@import 'global';

$small: 300px;
$medium: px;
$large: 1200px;

.mainContainer {
    width: 98% !important;
    margin: 0px !important;
    margin-left: 1% !important;
}

.margin10 {
    margin: 10px !important
}
.margin20 {
    margin: 20px !important
}

.marginPadding10 {
    margin: 10px !important;
    padding: 10px !important
}

.marginPadding20 {
    margin: 20px !important;
    padding: 20px !important
}

.button {
    width: 120px;
    padding: 10px !important;
    border-radius: 30px !important
}

.acceptButton {
    padding: 10px !important;
    border-radius: 30px !important
}

.cancelButton {
    color: $white;
    padding: 10px !important;
    border-radius: 30px !important;
    background: black !important
}

.noDecoration {
    text-decoration: none;
}

.perMonthText {
    color : $dark-blue;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 800;
}

.tag {
    background-color: $light-blue;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 10px;
}

.statisticsCount {
    margin-top: 10px;
}

.statistics {
    font-weight: 800;
    font-size: 20px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding: 10px;
}