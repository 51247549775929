@import 'global';

$small: 300px;
$medium: px;
$large: 1200px;

.Page {
	// color: "#494949";
	// margin: "5px 2px 5px 5px";

	.Banner
		{
		height: 90vh !important;
		position: relative;
	
		.Media
		{
			background-color: white;
			height: 100%;
			overflow: hidden;
	
			position: relative;
	
			.MediaCaption
			{
				text-overflow: ellipsis;
				text-align: center;
	
				position: absolute;
				bottom: 0;
	
				padding: 15px;
	
				background-color: black;
				color: white;
				opacity: 0.6;
	
				width: 100%;
				height: 10%;
	
				font:
				{
					size: $header-font-size;
					weight: 200;
				}
	
				transition: 300ms;
				cursor: pointer;
				&:hover
				{
					opacity: 0.8;
				}
				
			}
	
			transition: 300ms;
			cursor: pointer;
			// &:hover
			// {
			// 	filter: brightness(115%);
			// }
		}
	
		.BannerGrid
		{
			height: 90vh;
			position: relative;
		}
	
		.Content
		{
			color: white;
			background-color: $dark-red;
			height: 100%;
			
			position: relative;
	
			cursor: pointer;
	
			padding: 30px;
	
			transition: 300ms;
	
			&:hover, &:active
			{
				background-color: $dark-red-active;
	
				.ViewButton
				{
					// background-color: $almost-white;
					background-color: "#e00b0b";
					color: $dark-red;
				}
			}
	
			.Title
			{
				font-size: $huge-font-size;
				font-weight: 500;
			}
	
			.Caption
			{
				margin-top: 10px;
				font-size: $bigger-font-size;
			}
	
			.ViewButton
			{
				margin-top: 40px;
				color: white;
	
				font-size: $header-font-size;
				border: 3px solid white;
				text-transform: capitalize;
	
				transition: 200ms;
	
				&:hover {
					background-color: red;
				}
			}
		}
	}

	.SearchButton {
		left: 0;
		margin-top: 4px;
		font-size: 28px;
		border-radius: 30%;

		@media screen and (max-width: $small) {
			margin-top: 5px;
			font-size: 25px;
			border-radius: 30%;
			padding-left: 0px;
			padding-right: 15px;
		}
		@media screen and (min-width: $medium) and (max-width: $large){
			margin-top: 2px;
			font-size: 35px;
			left: 25%;
			border-radius: 30%;
		}

		@media screen and (min-width: $large) {
			margin-top: 0px;
			font-size: 35px;
			left: 50%;
			border-radius: 30%;
		}
	}

	.HostelMediaCaption {
		text-overflow: ellipsis;
		text-align: center;

		position: absolute;
		bottom: 0;

		padding: 15px;

		background-color: black;
		color: white;
		opacity: 0.6;

		width: 100%;
		height: 10%;

		font:
		{
			size: $header-font-size;
			weight: 200;
		}

		transition: 300ms;
		cursor: pointer;
		&:hover
		{
			opacity: 0.8;
		}
		
	}


}

